import React, { useState } from 'react';
import {Link,useLocation} from 'react-router-dom';
import '../App.css';


const HomePage = (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const location = useLocation();
    const passedState = location.state;

    //used to check for admin passed in through props
    let propsAdmin;
    console.log('xxx',props)
    //was admin passed in through props?
    if (passedState === null || !passedState.admin ){
        propsAdmin = false
    }
    else{
        propsAdmin = true
    }
    if (isAdmin === false && propsAdmin){
        setIsAdmin(true);
        }

    return (
        <>
            <div className='nav'>
                <div className='nav-img-cont'>
                    <img className='nav-img' src='biglogo2.jpg' alt='logo' ></img>
                </div>
                <div className='nav-menu'>
                    <Link className='menu-link' to={"/"} state={{admin: isAdmin}}> Home </Link>
                    <Link className='menu-link' to={"/about"} state={{admin: isAdmin}}> About </Link>
                    <Link className='menu-link' to={"/stories"} state={{admin: isAdmin}}> Stories </Link>
                    <Link className='menu-link' to={"/services"} state={{admin: isAdmin}}> Services </Link>
                    <Link className='menu-link-don' to={"/help"} state={{admin: isAdmin}}> Donate </Link>
                </div>
            </div>
            <div className='main-home'>
		{/* if you want to insert a header or banner at the top of the page, uncomment the below */}
	    	{/* <a className='header-banner' href="https://www.signupgenius.com/go/5080D4AAFA822A4FC1-53480599-holiday#/">You Can Help:  Holiday Wish Lists for Youth Aging out of Foster Care 2024</a>*/}
	        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators car-ind">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="IMG_4474_.jpg" className="d-block w-100 car-img" alt="..."/>
                            <div className="carousel-caption d-none d-md-block">
                                {/* <h5></h5>
                                <p></p> */}
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="gifts.jpeg" className="d-block w-100 car-img" alt="..."/>
                            <div className="carousel-caption d-none d-md-block">
                                {/* <h5></h5>
                                <p></p> */}
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="IMG_6029.jpg" className="d-block w-100 car-img" alt="..."/>
                            <div className="carousel-caption d-none d-md-block">
                                {/* <h5></h5>
                                <p></p> */}
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className='content-home'>
                    <div className='mission story-wrap'>
                        <h3 className='mission-title'>Our Mission:</h3>
                        <p className='mission-body main-text'>Helping Hands - There After Foster Care works with local social workers to meet the needs of young adults who are
                        aging out of foster care. While some of their needs are met through social service programs, they face challenges that youth with supportive parents do not. We step in to fill the gaps, supporting youth in their move toward independence. </p>
                    </div>
                    <div className='contact'>
                            <h3 className='contact-title'>Contact Us:</h3>
                            <p className='contact-body'>2770 Arapahoe Rd, Ste 132-1014, Lafayette, CO 80026</p>
                            <p className='contact-body'>thereafterfostercare@gmail.com</p>
                            <p className='contact-body'>703-629-8469</p>
                            <p className='contact-body'><a href='https://m.facebook.com/groups/565566044060182'>Join us on Facebook</a></p>
			    <p/>
			    <p className='contact-body'>Helping Hands-There After Foster Care is a non-profit, tax-exempt charitable organization (tax id 87-4344226) <br/>under section 501(c)(3) of the Internal Revenue Code.  Donations are tax-deductible as allowed by law.</p>
                        </div>
                </div>
            </div>
        </>
    )
}

export default HomePage;

